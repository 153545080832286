<router-outlet></router-outlet>
<!-- <cmp-notifications></cmp-notifications> -->

<div class="to-top bg-primary" (click)="scrollToTop()" [ngClass]="{ 'show-scrollTop': windowScrolled }">
    <div
        class="animate-bounce bg-white dark:bg-slate-800 p-2 w-10 h-10 ring-1 ring-slate-900/5 dark:ring-slate-200/20 shadow-lg rounded-full flex items-center justify-center"
    >
        <svg
            class="w-6 h-6 text-primary"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path d="M19 10l-7-7m0 0l-7 7m7-7v18"></path>
        </svg>
    </div>
</div>
